import * as React from 'react';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Tooltip from '@mui/joy/Tooltip';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import BlockIcon from '@mui/icons-material/Block';
import { visuallyHidden } from '@mui/utils';
import dayjs from 'dayjs';
import { Button, Chip, Divider, Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import { Fragment } from 'react';
import DataGridLoading from './DataGridLoading';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { useSelector } from 'react-redux';
    
export interface HeadCell {
    disablePadding: boolean;
    id: keyof any;
    label: string;
    numeric: boolean;
    type: string;
}

function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface EnhancedTableProps {
  headers: any;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  selected: string[];
  deleteList: Function;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, headers, order, orderBy, numSelected, rowCount, selected, deleteList, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <thead>
      <tr>
        <th>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            slotProps={{
              input: {
                'aria-label': 'select all desserts',
              },
            }}
            sx={{ verticalAlign: 'sub' }}
          />
        </th>
        {headers.map((headCell:any) => {
          const active = orderBy === headCell.id;
          
          return (
            <th
              key={headCell.id}
              aria-sort={
                active
                  ? ({ asc: 'ascending', desc: 'descending' } as const)[order]
                  : undefined
              }
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                underline="none"
                color="neutral"
                textColor={active ? 'primary.plainColor' : undefined}
                component="button"
                onClick={createSortHandler(headCell.id)}
                startDecorator={
                  headCell.numeric ? (
                    <ArrowDownwardIcon
                      sx={[active ? { opacity: 1 } : { opacity: 0 }]}
                    />
                  ) : null
                }
                endDecorator={
                  !headCell.numeric ? (
                    <ArrowDownwardIcon
                      sx={[active ? { opacity: 1 } : { opacity: 0 }]}
                    />
                  ) : null
                }
                sx={{
                  fontWeight: 'lg',

                  '& svg': {
                    transition: '0.2s',
                    transform:
                      active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                  },

                  '&:hover': { '& svg': { opacity: 1 } },
                }}
              >
                {headCell.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Link>
            </th>
          );
        })}
        <th style={{ width: 140, padding: '5px 8px', textAlign: 'right' }}>{numSelected > 0 && <Button size='sm' onClick={() => deleteList(selected)}><DeleteIcon /></Button>}</th>
      </tr>
    </thead>
  );
}

export default function TableSortAndSelection(props:any) {
  const { headers, data = [], setStoreGroupId, setShowGroupInvoices, setEditId, setDeleteId, deleteList, defaultId = 'id', defaultSortBy = 'id', defaultSort = 'asc' } = props;
  const is_loading = useSelector((state:any) => state.loading.is_loading);
  const [order, setOrder] = React.useState<Order>(defaultSort);
  const [orderBy, setOrderBy] = React.useState<keyof any>(defaultSortBy);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n:any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };
  const getLabelDisplayedRowsTo = () => {
    if (data.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? data.length
      : Math.min(data.length, (page + 1) * rowsPerPage);
  };
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty data.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const capitalizeFirstLetter = (txt:string) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1)
  }
  const RowMenu = (props:any) => {
    const { id, name = '' } = props;
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          { setEditId && <MenuItem onClick={() => setEditId(id)}>Edit</MenuItem> }
          { setShowGroupInvoices && <MenuItem onClick={() => setShowGroupInvoices(id, name)}>Invoices</MenuItem> }
          { setStoreGroupId && <MenuItem onClick={() => setStoreGroupId(id, name)}>Groups</MenuItem> }
          { setDeleteId && <><Divider />
          <MenuItem color="danger" onClick={() => setDeleteId(id)}>Delete</MenuItem></> }
        </Menu>
      </Dropdown>
    );
  };
  const workStatus = (type:string = '') => {
    const status:any = {
      active : {
        title:"Active",
        color:"success"
      },
      vacationing : {
        title:"Vacationing",
        color:"warning"
      },
      backwatch : {
        title:"Backwatch",
        color:"neutral"
      },
      offwork : {
        title:"Off work",
        color:"danger"
      }
    };
    return <Chip variant="soft" size="sm" color={status[type]?.color || "success"} >{status[type]?.title || type}</Chip>
  };
  return (
    <>
    {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
    <Sheet
      sx={{
        display: { xs: 'none', sm: 'initial' },
        width: '100%',
        buserRadius: 'sm',
        flexShrink: 1,
        overflow: 'auto',
        minHeight: 0,
      }}
    >
      <Table
        aria-labelledby="tableTitle"
        hoverRow
        stickyFooter
        stickyHeader
        sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-selectedBackground': (theme) =>
            theme.vars.palette.success.softBg,
          '& thead th:nth-of-type(1)': {
            width: '40px',
          },
          '& thead th:nth-of-type(n+2)': {
            width: 'auto',
          }
        }}
      >
        <EnhancedTableHead
          headers={headers}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy.toString()}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          selected={selected}
          deleteList={deleteList}
          rowCount={data.length}
        />
        <tbody>
          {data.length == 0 && !is_loading &&
            <tr>
              <td colSpan={(Object.keys(headers).length + 2)}>
                <div className='loadingGrid'>
                  <PrivacyTipOutlinedIcon />
                  <small>No Data</small>
                </div>
            </td>
          </tr>}
          {[...data]
            .sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <tr
                  // onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  // selected={isItemSelected}
                  style={
                    isItemSelected
                      ? ({
                          '--TableCell-dataBackground':
                            'var(--joy-palette-background-level1)',
                          '--TableCell-headBackground':
                            'var(--joy-palette-background-level1)',
                        } as React.CSSProperties)
                      : {}
                  }
                >
                  <td scope="row">
                    <Checkbox
                      onClick={(event) => handleClick(event, row.id)}
                      checked={isItemSelected}
                      slotProps={{
                        input: {
                          'aria-labelledby': labelId,
                        },
                      }}
                      sx={{ verticalAlign: 'top' }}
                    />
                  </td>
                  {headers?.map((item:any, i:number) => {
                    switch(item.type){
                      case 'status': return <td key={i} className='single-line'>{workStatus(row[item.id])}</td>; break;
                      case 'string': return <td key={i} className='single-line'>{row[item.id]}</td>; break;
                      case 'active': return <td key={i} className='single-line'><Chip variant="soft" size="sm" startDecorator={ row[item.id] ? <CheckRoundedIcon /> : <BlockIcon /> } color={ row[item.id] ? 'success' : 'danger' } >{ row[item.id] ? 'Active' : 'Inactive' }</Chip></td>; break;
                      case 'chip': return <td key={i} className='single-line'>{row[item.id] && <Chip variant="soft" size="sm" color='neutral' >{capitalizeFirstLetter(row[item.id].replaceAll('-',' ').replaceAll('_',' '))}</Chip>}</td>; break;
                      case 'toggle': return <td key={i} className='single-line'>{row[item.id] ? <ToggleOnOutlinedIcon color='success' /> : <ToggleOffOutlinedIcon color='error' />}</td>; break;
                      case 'date': return <td key={i} className='single-line'>{dayjs(row[item.id]).format('DD.MM.YYYY HH:mm')}</td>; break;
                      default: return <td key={i} className='single-line'>{row[item.id]}</td>; break;
                    }
                    
                  })}
                  <td >
                    { setEditId && setDeleteId && <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'end' }}>
                      <RowMenu id={row[defaultId]} name={row[defaultSortBy]} />
                    </Box> }
                  </td>
                </tr>
              );
            })}
          {emptyRows > 0 && (
            <tr
              style={
                {
                  height: `calc(${emptyRows} * 40px)`,
                  '--TableRow-hoverBackground': 'transparent',
                } as React.CSSProperties
              }
            >
              <td colSpan={(Object.keys(headers).length + 2)} aria-hidden />
            </tr>
          )}
          <DataGridLoading colSpan={(Object.keys(headers).length + 2)} />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={(Object.keys(headers).length + 2)}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}
              >
                <FormControl orientation="horizontal" size="sm">
                  <FormLabel>Rows per page:</FormLabel>
                  <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                    <Option value={5}>5</Option>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                  </Select>
                </FormControl>
                <Typography sx={{ textAlign: 'center', minWidth: 80 }}>
                  {labelDisplayedRows({
                    from: data.length === 0 ? 0 : page * rowsPerPage + 1,
                    to: getLabelDisplayedRowsTo(),
                    count: data.length === -1 ? -1 : data.length,
                  })}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page === 0}
                    onClick={() => handleChangePage(page - 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={
                      data.length !== -1
                        ? page >= Math.ceil(data.length / rowsPerPage) - 1
                        : false
                    }
                    onClick={() => handleChangePage(page + 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Sheet>
    </>
  );
}



// // Send from parent component

// const setDeleteList = (data:string[]) => {
//   // console.log("Delete List: ", data);  
//   toast.warning("We need to update API!");
// };

// const headCells: readonly HeadCell[] = [
//   {
//       id: 'visit_date',
//       numeric: false,
//       disablePadding: false,
//       label: 'Date',
//       type: 'date',
//   },
//   {
//       id: 'visitor_name',
//       numeric: false,
//       disablePadding: false,
//       label: 'Visitor',
//       type: 'string',
//   },
//   {
//       id: 'host_name',
//       numeric: false,
//       disablePadding: false,
//       label: 'Host',
//       type: 'string',
//   },
//   {
//       id: 'status',
//       numeric: false,
//       disablePadding: false,
//       label: 'Status',
//       type: 'chip',
//   },
//   {
//       id: 'store_id',
//       numeric: false,
//       disablePadding: false,
//       label: 'Store',
//       type: 'string',
//   }
// ];

// <AceGrid
// headers={headCells}
// data={tmpVisitors}
// setEditId={setEditId}
// setDeleteId={setDeleteId}
// deleteList={setDeleteList}
// defaultSortBy={'visit_date'}
// defaultSort={'desc'}
// defaultId={'id'}
// />