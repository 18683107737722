import React, {useState, useEffect} from 'react';
import { SatisfactionDevice } from '../api/satisfaction';
import Chart, { CategoryScale } from 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Grid from '@mui/joy/Grid';

Chart.register(CategoryScale);

const SatisfactionChart = (props:any) => {

    const { data } = props;
    return (
        <Grid >
            <Card variant="soft">
                <CardContent>
                    <Bar style={{minHeight: '150px'}}
                        data={{
                            labels: [""],
                            datasets: [
                                {
                                    label: "Bad",
                                    data: [data.distribution['1']],
                                    backgroundColor: "#a30000"
                                },
                                {
                                    label: "Solid",
                                    data: [data.distribution['2']],
                                    backgroundColor: "#d09200"
                                },
                                {
                                    label: "Good",
                                    data: [data.distribution['3']],
                                    backgroundColor: "#00a0a0"
                                },
                                {
                                    label: "Excellent",
                                    data: [data.distribution['4']],
                                    backgroundColor: "#189100"
                                },
                            ]
                        }}
                        options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: data.device,
                                    position: 'top'
                                },
                                legend: {
                                    display: true,
                                    position: 'top'
                                }
                            },
                            scales: {
                                x: {
                                    display: true,
                                    border: {
                                        color: '#4393e4'
                                    }
                                },
                                y: {
                                    display: true,
                                    border: {
                                        color: '#4393e4'
                                    }
                                }
                            }
                            
                        }}
                    />
                </CardContent>
            </Card>
        </Grid>
    );
};

export default SatisfactionChart;