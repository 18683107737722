/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { ColorPaletteProp } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Avatar from '@mui/joy/Avatar';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import Input from '@mui/joy/Input';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import SearchIcon from '@mui/icons-material/Search';

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { StoreGroupEdge } from '../../../api/storeGroups';
import { Store } from '../../../api/stores';
import { Sheet } from '@mui/joy';
import DataGridLoading from '../../../components/DataGridLoading';

export default function StoreGroupEdgesList(props:any) {
  const { storeGroupEdges = [], setEditId, setDeleteId, stores } = props;
  const [tmpStoreGroupEdges, setTmpStoreGroupEdges] = useState<StoreGroupEdge[]>(storeGroupEdges);
  const [searchByName, setSearchByName] = useState('');
  const loggedOrg = useSelector((state:any) => state.auth.logged); 

  useEffect(() => {
    setTmpStoreGroupEdges(storeGroupEdges);
  }, [storeGroupEdges]);

  useEffect(() => {
    setTmpStoreGroupEdges(storeGroupEdges.filter((item:StoreGroupEdge) => item.storeName.toLowerCase().includes(searchByName.toLowerCase())));
  }, [searchByName]);

  const getStoreName = (id:string) => {
    return (stores.find((item:Store) => item.id === id)?.name) || "";
  };
  
  const RowMenu = (props:any) => {
    const { id } = props;
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
            <MenuItem onClick={() => setEditId(id)}>Edit</MenuItem>
            { loggedOrg.extension_customClaims?.includes('super.admin') && <><Divider />
            <MenuItem color="danger" onClick={() => setDeleteId(id)}>Delete</MenuItem></>}
        </Menu>
      </Dropdown>
    );
  }
  
  return (
    <Fragment>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        {tmpStoreGroupEdges && tmpStoreGroupEdges.map((str:StoreGroupEdge) => (
          <List
            key={str.id}
            size="sm"
            sx={{
              '--ListItem-paddingX': 0,
            }}
          >
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
              }}
            >
              <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                <div>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 0.5,
                      mb: 1,
                    }}
                  >
                    <Typography level="body-xs">{str.storeName}<br /><small>{getStoreName(str.storeId)}</small></Typography>
                  </Box>
                </div>
              </ListItemContent>
              <RowMenu id={str.id}  />
            </ListItem>
            <ListDivider />
          </List>
        ))}
        <DataGridLoading />
        <Box
          className="Pagination-mobile"
          sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', py: 2 }}
        >
          <IconButton
            aria-label="previous page"
            variant="outlined"
            color="neutral"
            size="sm"
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography level="body-sm" mx="auto">
            Page 1 of 10
          </Typography>
          <IconButton
            aria-label="next page"
            variant="outlined"
            color="neutral"
            size="sm"
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
    </Fragment>
  );
}