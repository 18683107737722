import dayjs from "dayjs";

export const ChangeDateTime = (type:string, datetime:string, newValue:string) => {
    if (type === 'date'){
        let newDate = dayjs(newValue);
        let tmpDateTime = dayjs(datetime).set('date', newDate.date()).set('month', newDate.month()).set('year', newDate.year());
        debugger;
        return tmpDateTime.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }
    else if (type === 'time'){
        const newDate = dayjs(datetime).format('YYYY-MM-DD');
        const tmpDateTime = dayjs(`${newDate} ${newValue}`);
        return tmpDateTime.format();
    }
    else {
        return datetime;
    }
};