import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../../store/loadingSlice'
import { changeOrganzation } from '../../../store/aceSlice'
import Layout from '../../../template/layout';
import axios from 'axios';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { GetAllOrganizations, GetOrganization, CreateOrganization, UpdateOrganization, DeleteOrganization, Org } from '../../../api/organization';
import { Box, FormControl, FormLabel, Grid, Input, Select, Stack, Textarea, Typography, Option, Button, CardContent, Card, CardCover, Breadcrumbs, Link, ModalDialog, Modal, DialogTitle, DialogContent, Divider, DialogActions, ModalClose, LinearProgress, Stepper } from '@mui/joy';
import OrganizationsTable from './organizationsTable';
import OrganizationsList from './organizationsList';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

import { toast } from 'react-toastify';

import AppDeleteItemModal from '../../../components/AppDeleteItemModal';
import AceGrid, { HeadCell } from '../../../components/AceGrid';
import AppHeader from '../../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../../components/AppBreadcrumb';

const Organizations = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const defaultOrg = {
        id: "",
        name: ""
    };

    const [organizations, setOrganizations] = useState<Org[]>([]);
    const [tmpOrganizations, setTmpOrganizations] = useState<Org[]>(organizations);
    const [openOrgModal, setOpenOrgModal] = useState(false);  
    const [modalOrg, setModalOrg] = useState<Org>(defaultOrg); 
    const [modalLoading, setModalLoading] = useState(false);
    const [editId, setEditId] = useState<string | null>(null); 
    const [deleteId, setDeleteId] = useState<string | null>(null); 
    const loggedOrg = useSelector((state:any) => state.auth.logged);
    const organization_id = localStorage.getItem('organization_id') || "";
    const [searchByName, setSearchByName] = useState('');
  
    useEffect(() => {
        setTmpOrganizations(organizations);
    }, [organizations]);
  
    useEffect(() => {
        setTmpOrganizations(organizations.filter((item:Org) => 
            ((item?.id.toLowerCase()).includes(searchByName.toLowerCase()) || ((item?.name.toLowerCase()).includes(searchByName.toLowerCase())
        ))));
    }, [searchByName]);


    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };

    const fetchOrganizations = async () => {
        setLoading(true);
        setOrganizations([]);
        GetAllOrganizations()
            .then(orgs => {
                !loggedOrg.extension_customClaims?.includes('super.admin') ? setOrganizations(orgs.filter(item => item.id === loggedOrg.extension_organizationId)) :
                setOrganizations(orgs);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    };

    const fetchOrganization = async (id: string) => {
        if(id){
            setOpenOrgModal(true);
            setModalLoading(true);
            GetOrganization(id)
                .then(org => {
                    org && setModalOrg(org);
                    setModalLoading(false);
                })
                .catch(error => console.error(error));
        }
    };
  
    const updateOrganization = async ( organization: Org) => {
      try {
        // Use the same form to Create or Update Organization
        setLoading(true);
        setOrganizations([]);
        if(editId){
            UpdateOrganization(organization)
                .then(user => {
                    fetchOrganizations();
                    setOpenOrgModal(false);
                    setEditId(null);
                })
                .catch(error => console.error(error));
        } else {
            CreateOrganization(organization)
                .then(user => {
                    fetchOrganizations();
                    setOpenOrgModal(false);     
                    setModalOrg(defaultOrg);
                })
                .catch(error => console.error(error));
        }
      } catch (error) {
        console.log(error);
      }
    };

    const DeleteOrg= async (id: string) => {
        if(id){
            try {
            setLoading(true);
            setOrganizations([]);
            DeleteOrganization(id)
                .then(user => {
                    fetchOrganizations();
                    setDeleteId(null);
                })
                .catch(error => console.error(error));
            } catch (error) {
            console.log(error);
            }
        }
    };

    const deleteOrganization = async (id: string) => {
      id && await DeleteOrg(id);
      setOpenOrgModal(false);
      setDeleteId(null);
    };
    
    const setDeleteList = (data:string[]) => {
        // console.log("Delete List: ", data);  
        toast.warning("We need to update API!");
    };

    const newOrganization = () => {  
        setEditId(null);
        setDeleteId(null);      
        setModalOrg(defaultOrg);
        setOpenOrgModal(true);
    };

    useEffect(() => {
        fetchOrganizations();
    },[]);
  
    useEffect(() => {        
        editId && fetchOrganization(editId);      
    },[editId]);

    const headCells: readonly HeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
            type: 'string',
        }
    ];

    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'Organizations',
            url: '/organizations',
            current: true
        },
    ];

    return (
    <Layout>
        <Box
            component="main"
            className="MainContent"
            sx={{
            px: { xs: 2, md: 6 },
            pt: {
                xs: 'calc(12px + var(--Header-height))',
                sm: 'calc(12px + var(--Header-height))',
                md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
            }}
            style={{
            position: 'relative',
            maxWidth: '1400px',
            margin: '0 auto'
            }}
        >            
            <AppBreadcrumb data={navLinks} />

            <AppHeader
                background={'https://images.unsplash.com/photo-1517502884422-41eaead166d4?auto=format&w=1000&dpr=2'}
                title={'Organizations'}
            >
                <Button
                    color="success"
                    size="sm"
                    onClick={() => fetchOrganizations()}
                >
                    <ReplayOutlinedIcon />
                </Button>
                <Button
                    color="primary"
                    startDecorator={<AddIcon />}
                    size="sm"
                    disabled={!loggedOrg.extension_customClaims?.includes('super.admin')}
                    onClick={() => newOrganization()}
                >
                    Add Organization
                </Button>
            </AppHeader>

            <Box
                className="SearchAndFilters-tabletUp"
                sx={{
                buserRadius: 'sm',
                py: 2,
                display: { xs: 'none', sm: 'flex' },
                flexWrap: 'wrap',
                gap: 1.5,
                '& > *': {
                    minWidth: { xs: '120px', md: '160px' },
                },
                }}
            >
                <FormControl sx={{ flex: 1 }} size="sm">
                <FormLabel>Search for organization by name (or ID)</FormLabel>
                <Input value={searchByName} onChange={e => setSearchByName(e.target.value)} size="sm" variant='soft' placeholder="Search" startDecorator={<SearchIcon />} />
                </FormControl>
            </Box>

                
            <AceGrid
                headers={headCells}
                data={tmpOrganizations}
                setEditId={setEditId}
                setDeleteId={setDeleteId}
                deleteList={setDeleteList}
                defaultSortBy={'visit_date'}
                defaultSort={'desc'}
                defaultId={'id'}
            />
            
            {/* <OrganizationsTable organizations={organizations} setEditId={setEditId} setDeleteId={setDeleteId} /> */}
            <OrganizationsList
                organizations={organizations}
                setEditId={setEditId}
                setDeleteId={setDeleteId}
            />

            
        </Box>


        <Modal open={openOrgModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
            if(reason !== 'backdropClick'){
                setModalOrg(defaultOrg);
                setOpenOrgModal(false);
                setEditId(null);
                setDeleteId(null);    
            }
        }} >
            <ModalDialog layout='center' variant='plain'>
                <ModalClose />
                <DialogTitle>Organization</DialogTitle>
                <DialogContent>Insert organization information<br />&nbsp;</DialogContent>
                
            {modalLoading ? <LinearProgress
                color="primary"
                determinate={false}
                size="sm"
                variant="soft"
                /> :
                <form
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                    updateOrganization(modalOrg);
                }}
                >
                <Stack spacing={2}>
                <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input 
                    required 
                    variant="soft" 
                    size="sm" 
                    value={modalOrg.name}
                    onChange={(e) => setModalOrg({
                        ...modalOrg,
                        name: e.target.value
                        }
                    )}
                    />
                </FormControl>


                
                <Box
                    sx={{
                    mt: 1,
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', sm: 'row' },
                    }}
                >
                    { loggedOrg.extension_customClaims?.includes('super.admin') && modalOrg.id && <Button
                        onClick={() => {
                            toast.warning(`Temporary Organization: ${modalOrg.name}!`);
                            localStorage.setItem('organization_id', modalOrg.id);
                            dispatch(changeOrganzation(modalOrg.id));
                            setOpenOrgModal(false);
                        }}
                        startDecorator={<ReportGmailerrorredOutlinedIcon />}
                        type="button"
                        color='danger'
                        disabled={modalOrg.id === localStorage.getItem('organization_id')}
                    >
                            Temporary use
                    </Button>}
                    <Button type="submit" sx={{ flex: 1 }}>Save</Button>
                </Box>
                </Stack>
                </form>}
            </ModalDialog>
        </Modal>

        <AppDeleteItemModal
            title={'Delete organization'}
            description={'Are you sure you want to delete organization?'}
            deleteId={deleteId}
            setDeleteId={setDeleteId}
            deleteFunction={deleteOrganization}
        />
    </Layout>
)};

export default Organizations;