import React, {useState, useEffect, Fragment} from 'react';
import { Box,
    Button,
    Typography,
    Stack, 
    DialogTitle, 
    DialogContent, 
    FormControl, 
    FormLabel, 
    Input, 
    Modal, 
    ModalDialog, 
    ModalClose, 
    Select, 
    Option, 
    Divider, 
    Sheet,
    StepButton, 
    typographyClasses
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import IconButton from '@mui/joy/IconButton';
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../store/loadingSlice'
import Layout from '../../template/layout';
import VisitorsList from './VisitorsList';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import uuid from '../../res/uuid';

import {
    GetAllVisitors,
    CreateVisitor,
    UpdateVisitor,
    DeleteVisitor,
    VisitorProfile
} from '../../api/visitation';

import Check from '@mui/icons-material/Check';
import { GetAllStores, Store } from '../../api/stores';
import { GetAllUserProfiles, User, UserProfile } from '../../api/users';
import { ChangeDateTime } from '../../res/datetime';
import AceGrid, { HeadCell } from '../../components/AceGrid';
import AppHeader from '../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../components/AppBreadcrumb';
import AppDeleteItemModal from '../../components/AppDeleteItemModal';
import QRCodeComponent from '../../components/QRCodeComponent';

const Visitors = () => {    
    const defaultVisitor = {
        check_in_code: "",
        purpose: "",
        visit_date: dayjs(new Date()).format(),
        visitor_name: "",
        visitor_phone: "",
        tenant_id: localStorage.getItem('organization_id') || "",
        host_name: "",
        host_phone: "",
        store_id: "",
    };
    const [visitors, setVisitors] = useState<VisitorProfile[]>([]);
    const [stores, setStores] = useState<Store[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [statuses, setStatuses] = useState<any>([]); 
    const [tmpVisitors, setTmpVisitors] = useState<VisitorProfile[]>(visitors);
    const [openCreateUpdateModal, setOpenCreateUpdateModal] = useState(false);  
    const [modalVisitor, setModalVisitor] = useState<VisitorProfile>(defaultVisitor); 
    const [editId, setEditId] = useState<string | null>(null); 
    const [deleteId, setDeleteId] = useState<string | null>(null); 
    const organization_id = localStorage.getItem('organization_id') || "";
    const [searchByName, setSearchByName] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false);  
    const [modalVisitorStep, setModalVisitorStep] = useState(0); 
    const [selectedHost, setSelectedHost] = useState<string>('');  
    const [selectedStatus, setSelectedStatus] = useState<string>('');  
    const [selectedStore, setSelectedStore] = useState<string>(''); 
    
    const [showQRCodeModal, setShowQRCodeModal] = useState<string | null>(null);

    const steps = ['', ''];   

    useEffect(() => {
        setTmpVisitors(visitors);
        console.log(visitors);
        
    }, [visitors]);
  
    useEffect(() => {
        setTmpVisitors(visitors.filter((item:VisitorProfile) => 
            ((item?.visitor_name.toLowerCase()+item.purpose.toLowerCase()).includes(searchByName.toLowerCase())
            && (selectedStatus.length ? (item?.status?.toLowerCase() === selectedStatus.toLowerCase()) : true)
            && (selectedStore.length ? (item?.store_id?.toLowerCase() === selectedStore.toLowerCase()) : true)
            && (selectedHost.length ? (item?.host_name?.toLowerCase() === selectedHost.toLowerCase()) : true)
        )));
    }, [searchByName, selectedHost, selectedStatus, selectedStore]);

    const dispatch = useDispatch();
  
    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };

    const getStoreName = (id:string) => {
        return (stores.find((item:Store) => item.id === id)?.name || '');
    };
  
    const fetchVisitors = async () => {
        setLoading(true);
        setVisitors([]);
        stores && GetAllVisitors(organization_id)
            .then(data => {
                if(data.visitors){
                    setVisitors(data?.visitors.map((itm:VisitorProfile) => ({
                        ...itm, 
                        store: getStoreName(itm.store_id),
                        host_phone: getHostByName(itm.host_name)?.companyPhoneNumber || itm.host_phone
                    })));
                    setLoading(false);
                    setStatuses(Array.from(new Set(data?.visitors.map(item => item.status))));
                }
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };
  
    const fetchStores = async () => {
        setLoading(true);
        organization_id && GetAllStores(organization_id)
            .then(data => {
                setStores(data);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };
  
    const fetchUserProfiles = async () => {
        setLoading(true);
        GetAllUserProfiles()
            .then(users => {
                setUsers(users);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };    

    const getHostByName = (name:string) => {        
        return (users.find((item:User) => item?.fullName === name) || null);
    };

    const getVisitor = (visitor_id:string) => {        
        return (visitors.find((item:VisitorProfile) => item.id === visitor_id) || null);
    };

    const fetchVisitor = async (visitor_id: string) => {
        if(visitor_id){
            const selectedVisitor = getVisitor(visitor_id);
            setOpenCreateUpdateModal(true);
            if(selectedVisitor){
                setModalVisitor(selectedVisitor);
            }
        }
    };

    const deleteVisitor = async (visitor_id: string) => {
        visitor_id && await DeleteVisitor(visitor_id, organization_id);
        fetchVisitors();
        setOpenCreateUpdateModal(false);
        setDeleteId(null);
    };

    const newVisitor = async () => {
        // console.log("defaultVisitor: ", defaultVisitor);        
        setEditId(null);
        setDeleteId(null);
        setModalVisitor(defaultVisitor);
        setOpenCreateUpdateModal(true);
        setModalVisitorStep(0);
    };
  
    const updateVisitor = async () => {
        // Use the same form to Create or Update Visitor
        setLoading(true);
        if(editId){
            setVisitors([]);
            modalVisitor.id && UpdateVisitor(modalVisitor.id, organization_id, modalVisitor)
                .then(visitor => {
                    fetchVisitors();
                    setModalVisitor(defaultVisitor);
                    setOpenCreateUpdateModal(false);
                    setEditId(null);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        } else {
            modalVisitor && CreateVisitor(modalVisitor)
                .then(visitor => {
                    fetchVisitors();
                    setModalVisitor(defaultVisitor);
                    setOpenCreateUpdateModal(false);
                    setEditId(null);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        setLoading(true);
        setVisitors([]);
        fetchUserProfiles();
        fetchStores();
    },[]);
  
    useEffect(() => {        
        editId && fetchVisitor(editId);      
    },[editId]);
  
    useEffect(() => {        
        stores && fetchVisitors();      
    },[stores]);
    
    const setDeleteList = (data:string[]) => {
        // console.log("Delete List: ", data);  
        toast.warning("We need to update API!");
    };

    const headCells: readonly HeadCell[] = [
        {
            id: 'visit_date',
            numeric: false,
            disablePadding: false,
            label: 'Date',
            type: 'date',
        },
        {
            id: 'visitor_name',
            numeric: false,
            disablePadding: false,
            label: 'Visitor',
            type: 'string',
        },
        {
            id: 'host_name',
            numeric: false,
            disablePadding: false,
            label: 'Host',
            type: 'string',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            type: 'chip',
        },
        {
            id: 'store',
            numeric: false,
            disablePadding: false,
            label: 'Store',
            type: 'string',
        }
    ];

    const capitalizeFirstLetter = (txt:string) => {
        return txt.charAt(0).toUpperCase() + txt.slice(1)
    }

    const generateQRCode = (type:string) => {
        switch(type){
            case 'evacuation': return `${process.env.REACT_APP_EVACUATION_API}/?tenant_id=${localStorage.getItem('organization_id') || ''}&sig=${uuid()}`;
            case 'visitors': return `${process.env.REACT_APP_VISITATION}/?tenant_id=${localStorage.getItem('organization_id') || ''}&device_id=${uuid()}`;
            default: return '';
        }
    };

    const EventStep = (props:any) => {
        const {title = '', id = 0, events = [], last = false} = props;
        const event = events[id] || null;        
        return (
            <Step
                disabled={event === null}
                completed={event !== null}
                indicator={
                    event !== null ? <StepIndicator variant="solid" color="success">
                      <CheckRoundedIcon />
                    </StepIndicator> : <StepIndicator>{id+1}</StepIndicator>}
                className={last&&'last'}
            >
                <div>
                <Typography level="title-sm">{event ? dayjs(event?.timestamp).format('DD.MM.YYYY - HH:mm') : '/'}</Typography>
                    {title}
                </div>
            </Step>
        );
    }

    const renderFilters = () => (
      <Fragment>
        <FormControl size="sm">
            <FormLabel>Host</FormLabel>
            <Select placeholder="All"
                size="sm"
                variant="soft"
                value={selectedHost}
                disabled={!users}
                onChange={(e, newValue) => setSelectedHost(newValue !== null ? newValue : '')}
            >
                <Option value="">All</Option>
                {users && users.map((item, index) => <Option key={index} value={item.fullName}>{item.fullName}</Option>)}
            </Select>
        </FormControl>
        <FormControl size="sm">
            <FormLabel>Status</FormLabel>
            <Select placeholder="All"
                size="sm"
                variant="soft"
                value={selectedStatus}
                disabled={!stores}
                onChange={(e, newValue) => setSelectedStatus(newValue !== null ? newValue : '')}
            >
                <Option value="">All</Option>
                {statuses && statuses.map((x:string) => x && <Option key={x} value={x}>{capitalizeFirstLetter(x.replaceAll('-',' ').replaceAll('_',' '))}</Option>)}
            </Select>
        </FormControl>
        <FormControl size="sm">
            <FormLabel>Store</FormLabel>
            <Select placeholder="All"
                size="sm"
                variant="soft"
                value={selectedStore}
                disabled={!stores}
                onChange={(e, newValue) => setSelectedStore(newValue !== null ? newValue : '')}
            >
                <Option value="">All</Option>
                {stores && stores.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
            </Select>
        </FormControl>
      </Fragment>
    );

    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'Visitors',
            url: '/visitors',
            current: true
        },
    ];

    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >
                <AppBreadcrumb data={navLinks} />

                <AppHeader
                    background={'https://images.unsplash.com/photo-1634482897361-7f327bb7d009?auto=format&w=1000&dpr=2'}
                    title={'Visitors'}
                >
                    
                    <Button
                        color="danger"
                        size="sm"
                        onClick={() => setShowQRCodeModal('evacuation')}
                    >
                        <DirectionsRunOutlinedIcon />
                    </Button>
                    <Button
                        color="warning"
                        size="sm"
                        onClick={() => setShowQRCodeModal('visitors')}
                    >
                        <QrCodeScannerOutlinedIcon />
                    </Button>
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => fetchVisitors()}
                    >
                        <ReplayOutlinedIcon />
                    </Button>
                    <Button
                        color="primary"
                        startDecorator={<AddIcon />}
                        size="sm"
                        onClick={() => newVisitor()}
                    >
                        Add visitor
                    </Button>
                </AppHeader>

                {/* https://mui.com/joy-ui/react-table/ */}

                {/* Filters - Mobile    */}
                <Sheet
                    className="SearchAndFilters-mobile"
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        my: 1,
                        gap: 1,
                    }}
                >
                    <Input
                        size="sm"
                        variant='soft'
                        placeholder="Search"
                        value={searchByName} onChange={e => setSearchByName(e.target.value)}
                        startDecorator={<SearchIcon />}
                        sx={{ flexGrow: 1 }}
                    />
                    <IconButton
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={() => setFiltersOpen(true)}
                    >
                        <FilterAltIcon />
                    </IconButton>
                    <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
                        <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose />
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {renderFilters()}
                            <Button color="primary" onClick={() => setFiltersOpen(false)}>
                            Submit
                            </Button>
                        </Sheet>
                        </ModalDialog>
                    </Modal>
                </Sheet>

                {/* Filters- Web */}

                <Box
                    className="SearchAndFilters-tabletUp"
                    sx={{
                    buserRadius: 'sm',
                    py: 2,
                    display: { xs: 'none', sm: 'flex' },
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: { xs: '120px', md: '160px' },
                    },
                    }}
                >
                    <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for visitor</FormLabel>
                    <Input value={searchByName} onChange={e => setSearchByName(e.target.value)} size="sm" variant='soft' placeholder="Search" startDecorator={<SearchIcon />} />
                    </FormControl>
                    {renderFilters()}
                </Box>

                <AceGrid
                    headers={headCells}
                    data={tmpVisitors}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                    deleteList={setDeleteList}
                    defaultSortBy={'visit_date'}
                    defaultSort={'desc'}
                    defaultId={'id'}
                />

                <VisitorsList
                    visitors={tmpVisitors}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                />
            </Box>
            <Modal open={openCreateUpdateModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                    setModalVisitor(defaultVisitor);
                    setOpenCreateUpdateModal(false);
                    setModalVisitorStep(0);
                    setEditId(null);
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                    <ModalClose />
                    <DialogTitle>Visitor</DialogTitle>
                    <form
                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                            modalVisitorStep < 1 ? 
                            setModalVisitorStep(modalVisitorStep + 1) :
                            updateVisitor();
                        }}
                        >
                        <DialogContent>Insert visitor information</DialogContent><br />
                        <Tabs aria-label="Basic tabs" defaultValue={0}>
                            <TabList>
                                <Tab>Details</Tab>
                                <Tab disabled={!modalVisitor.events}>Events</Tab>
                                <Tab disabled={!modalVisitor.events}>Download</Tab>
                            </TabList>
                            <TabPanel value={0}>
                                <Stepper sx={{ width: '100%', margin: '15px 0px' }}>
                                {steps && steps.map((step, index) => (
                                    <Step
                                    key={index}
                                    indicator={
                                        <StepIndicator
                                        variant={modalVisitorStep <= index ? 'soft' : 'solid'}
                                        color={modalVisitorStep < index ? 'neutral' : 'primary'}
                                        >
                                        {modalVisitorStep <= index ? index + 1 : <Check />}
                                        </StepIndicator>
                                    }
                                    sx={{
                                        '&::after': {
                                        ...(modalVisitorStep > index &&
                                            index !== 2 && { bgcolor: 'primary.solidBg' }),
                                        },
                                    }}
                                    >
                                    <StepButton onClick={() => index < modalVisitorStep && setModalVisitorStep(index)}>{step}</StepButton>
                                    </Step>
                                ))}
                                </Stepper>
                                {modalVisitorStep === 0 &&
                                <Stack spacing={2}>
                                    <FormControl>
                                        <FormLabel>Visitor name</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalVisitor?.visitor_name}
                                        onChange={(e) => setModalVisitor({
                                            ...modalVisitor,
                                            visitor_name: e.target.value
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Visitor phone</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalVisitor?.visitor_phone}
                                        onChange={(e) => setModalVisitor({
                                            ...modalVisitor,
                                            visitor_phone: e.target.value
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl size="sm">
                                        <FormLabel>Host</FormLabel>
                                        <Select placeholder="Select host"
                                            size="sm"
                                            variant="soft"
                                            value={modalVisitor?.host_name}
                                            disabled={!users}
                                            onChange={(e, newValue) => setModalVisitor({
                                                ...modalVisitor,
                                                host_name: newValue !== null ? newValue : '',
                                                host_phone: getHostByName(newValue !== null ? newValue : '')?.companyPhoneNumber || null
                                            })}
                                        >
                                            {users && users.map((item, index) => <Option key={index} value={item.fullName}>{item.fullName}</Option>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Host phone</FormLabel>
                                        <Input 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalVisitor?.host_phone || ""}
                                        onChange={(e) => setModalVisitor({
                                            ...modalVisitor,
                                            host_phone: e.target.value
                                        })}
                                        />
                                    </FormControl>
                                    <Button type="submit">Next</Button>
                                </Stack>}
                                {modalVisitorStep === 1 &&
                                <Stack spacing={2}>
                                    <FormControl size="sm">
                                        <FormLabel>Store</FormLabel>
                                        <Select placeholder="Select store"
                                            size="sm"
                                            variant="soft"
                                        value={modalVisitor?.store_id}
                                            disabled={!stores}
                                            onChange={(e, newValue) => setModalVisitor({
                                                ...modalVisitor,
                                                store_id: newValue !== null ? newValue : ''
                                            })}
                                        >
                                            {stores && stores.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl size="sm">
                                        <FormLabel>Date</FormLabel>
                                        <Stack direction={'row'} gap={1}>
                                            <FormControl>
                                                <Input 
                                                required 
                                                variant="soft" 
                                                size="sm" 
                                                type="date"
                                                value={dayjs(modalVisitor?.visit_date).format('YYYY-MM-DD')}
                                                onChange={(e) => setModalVisitor({
                                                    ...modalVisitor,
                                                    visit_date: ChangeDateTime('date', modalVisitor?.visit_date, e.target.value)
                                                })}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                    <Input 
                                                    required 
                                                    variant="soft" 
                                                    size="sm" 
                                                    type="time"
                                                    value={dayjs(modalVisitor?.visit_date).format('HH:mm:ss')}
                                                    onChange={(e) => setModalVisitor({
                                                        ...modalVisitor,
                                                        visit_date: ChangeDateTime('time', modalVisitor?.visit_date, e.target.value)
                                                    })}
                                                    />
                                            </FormControl>
                                        </Stack>
                                    </FormControl>
                                    <FormControl size="sm">
                                        <FormLabel>Purpose</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalVisitor?.purpose}
                                        onChange={(e) => setModalVisitor({
                                            ...modalVisitor,
                                            purpose: e.target.value
                                        })}
                                        />
                                    </FormControl>
                                    { modalVisitor?.check_in_code && <FormControl size="sm">
                                        <FormLabel>Code</FormLabel>
                                        <Input 
                                        disabled 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalVisitor?.check_in_code}
                                        />
                                    </FormControl>}
                                    <Button type="submit">Save</Button>
                                </Stack>}
                            </TabPanel>
                            {modalVisitor?.events && <TabPanel value={1} style={{margin: '1rem 0'}}>
                                <Stepper
                                orientation="vertical"
                                sx={(theme) => ({
                                    '--Stepper-verticalGap': '2rem',
                                    '--StepIndicator-size': '2rem',
                                    '--Step-gap': '1rem',
                                    '--Step-connectorInset': '0.5rem',
                                    '--Step-connectorRadius': '1rem',
                                    '--Step-connectorThickness': '4px',
                                    '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
                                    [`& .${stepClasses.completed}`]: {
                                        '&::after': { bgcolor: 'success.solidBg' },
                                    },
                                    [`& .${stepClasses.disabled} *`]: {
                                        color: 'neutral.softDisabledColor',
                                    },
                                    [`& .${typographyClasses['title-sm']}`]: {
                                        textTransform: 'uppercase',
                                        letterSpacing: '1px',
                                        fontSize: '10px',
                                    },
                                    [`& .last`]: {
                                        '&::after': { display: 'none' },
                                    },
                                })}
                                >
                                <EventStep title={'Pre Registered'} id={0} events={modalVisitor.events} />
                                <EventStep title={'Checked In'} id={1} events={modalVisitor.events} />
                                <EventStep title={'Checked Out'} id={2} events={modalVisitor.events} last />    
                                </Stepper>
                            </TabPanel>}
                            {modalVisitor?.events && <TabPanel value={2} style={{margin: '1rem 0'}}>
                                <Stack spacing={2}>
                                    { modalVisitor.ics && <Button onClick={() => window.open( "data:text/calendar;charset=utf8," + modalVisitor.ics,  "_blank")} startDecorator={<ScheduleSendOutlinedIcon />}>Add to Calendar</Button> }
                                </Stack>
                            </TabPanel>}
                        </Tabs>
                    </form>
                </ModalDialog>
            </Modal>
            <AppDeleteItemModal
                title={'Delete visitor'}
                description={'Are you sure you want to delete visitor?'}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                deleteFunction={deleteVisitor}
            />
            
            <Modal open={showQRCodeModal !== null} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                    setShowQRCodeModal(null);
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                    <ModalClose />
                    { showQRCodeModal === 'evacuation' && <DialogTitle>Evacuation QR Code</DialogTitle> }
                    { showQRCodeModal === 'visitors' && <DialogTitle>Visitors QR Code</DialogTitle> }
                    <br />
                    <DialogContent>
                        <QRCodeComponent url={generateQRCode(showQRCodeModal || '')} />
                    </DialogContent>
                    <small>{}</small>
                </ModalDialog>
            </Modal>
        </Layout>
    );
};

export default Visitors;